import React, { useState } from "react";
import { Button, DatePicker, Form, Input, InputNumber, Modal, notification, Radio } from "antd";
import dayjs from 'dayjs';
import "./topCakes.css";

const TG_NOTI_URL = 'https://n29pbsmbbf.execute-api.ca-central-1.amazonaws.com/default/noraCakeAppSendToTelegram';
const NOT_API_KEY = 'LRrcjp2Rne2GcRdAjnopi6KvL19hL0Li3g3IMEjF';

const TopCakes = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  
  const [orderCakeName, setOrderCakeName] = useState('');
  const [customerName, setCustomerName] = useState();
  const [customerNameElementStatus, setCustomerNameElementStatus] = useState('');
  const [customerRequirement, setCustomerRequirement] = useState('');
  const [customerPreferredDate, setCustomerPreferredDate] = useState('');
  const [customerPreferredDateStatus, setCustomerPreferredDateStatus] = useState('');
  
  const [customerContactPhone, setCustomerContactPhone] = useState('');
  const [customerContactPhoneStatus, setCustomerContactPhoneStatus] = useState('');
  const [cakeOrderQuantity, setCakeOrderQuantity] = useState(1);
  const [deliveryValue, setDeliveryValue] = useState(1);
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [deliveryAddressStatus, setDeliveryAddressStatus] = useState('');

  const showModal = (cakeName) => {
    setOrderCakeName(cakeName);
    setIsModalOpen(true);
  };

  
  const openNotification = (cakeName) => {
    api.info({
      message: `You ${cakeName} will be placed in our oven soon.`,
      description:
        'We will contact you soon to confirm your order and proceed the payment. Thank you for choosing Nora Cake!',
      placement: 'top',
    });
  };

  const isCustomerNameValid = () => {
    const isValid = (customerName !== undefined && customerName !== '');
    if (!isValid) {
      setCustomerNameElementStatus('error')
    } else {
      setCustomerNameElementStatus('')
    }
    return isValid;
  }

  const today = dayjs()
  const minDate = today.add(1, 'day');
  const maxDate = today.add(1, 'month');
  const isCustomerPreferredDateValid = () => {
    const isValid = (customerPreferredDate !== undefined && customerPreferredDate !== '');
    if (!isValid) {
      setCustomerPreferredDateStatus('error')
    } else {
      setCustomerPreferredDateStatus('')
    }
    return isValid;
  }

  const isCustomerPhoneValid = () => {
    let isValid = (customerContactPhone !== undefined && customerContactPhone !== '');
    if (isValid) {
      const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      isValid = phoneRegex.test(customerContactPhone);
    }

    if (!isValid) {
      setCustomerContactPhoneStatus('error')
    } else {
      setCustomerContactPhoneStatus('')
    }
    return isValid;
  }

  const isDeliveryAddressValid = () => {
    const isValid = (deliveryAddress !== undefined && deliveryAddress !== '');
    if (!isValid) {
      setDeliveryAddressStatus('error')
    } else {
      setDeliveryAddressStatus('')
    }
    return isValid;
  }

  const handleOk = async(e) => {
    if (!isCustomerNameValid())
      return;
    if (!isCustomerPreferredDateValid())
      return;
    if (!isCustomerPhoneValid())
      return;
    if (deliveryValue === 2) {
      if (!isDeliveryAddressValid()) {
        return;
      }
    }

    let data = `*** Customer request ***
    Name: ${customerName}
    Phone: ${customerContactPhone}
    Cake: ${orderCakeName}
    Quantity: ${cakeOrderQuantity}
    Date: ${customerPreferredDate.toString()}
    Requirement: ${customerRequirement}`;
    if (deliveryValue === 2) {
      data =  data + `
    Delivery address: ${deliveryAddress}`;
    }

    setLoading(true);
    await fetch(TG_NOTI_URL, {
      method: 'POST',
      headers: { 'x-api-key': NOT_API_KEY },
      body: JSON.stringify(data),
    }).then(response => {
      if (!response.ok) {
        alert("Error happening while placing the order")
        console.error(response);
        setLoading(false);
        setIsModalOpen(false);
      } else {
        setLoading(false);
        openNotification(orderCakeName);
        setIsModalOpen(false);
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setDeliveryValue(e.target.value);
  };

  const onCustomerNameChange = (e) => {
    console.log('Customer name change', e.target.value);
    setCustomerNameElementStatus('');
    setCustomerName(e.target.value);
  };

  const onCustomerRequirementChange = (e) => {
    console.log('Customer requreiement change', e.target.value);
    setCustomerRequirement(e.target.value);
  };

  const onCustomerPreferredDate = (e) => {
    console.log('Customer preferred date change', e);
    setCustomerPreferredDateStatus('');
    setCustomerPreferredDate(e);
  };
  
  const onCustomerContactPhoneChange = (e) => {
    console.log('Customer contact phone change', e.target.value);
    setCustomerContactPhoneStatus('');
    setCustomerContactPhone(e.target.value);
  };

  const onCakeOrderQuantityChange = (e) => {
    console.log('Cake quantity change', e);
    setCakeOrderQuantity(e);
  };

  const onDeliveryAddressChange = (e) => {
    console.log('Delivery address change', e.target.value);
    setDeliveryAddressStatus('');
    setDeliveryAddress(e.target.value);
  };
  
  return (
    <>
      {contextHolder}
      <div
        className="container-sm px-4 px-xs-5 px-sm-0 align-items-center justify-content-center home__topnft"
        id="top-orders">
        <h2 className="text-center">
          <b>Party and birthday cakes</b>
        </h2>
        <p className="text-center mb-4">
          A list of cakes customers want for their birthday and casual parties.{" "}
        </p>
        <div className="row">
          <div className="col-md-6 col-sm-6 py-5 gap-4 d-flex flex-column align-items-center">
            <div className="bg-white rounded_div shadow-lg overflow-hidden flex-1 flex flex-col">
              <img
                  src="/assets/cakes/raffaello-cake.jpg"
                  alt=""
                  className="home__topnft_img"/>
              <div className="p-4 flex-1 flex flex-col">
                <h3 className="mb-4 text-2xl">Raffaello cake</h3>
                <div className="mb-4 text-grey-darker text-sm flex-1">
                  <p><b><i>Taste:</i></b> Soft and balanced sweet. </p>
                  <p>&emsp;Best to consume after keeping ~30mins in refrigerator. </p>
                  <p><b><i>Ingredients:</i></b> egg, flour, whipping cream, Raffaello sweets, coconut powder.</p>
                </div>
                <Button type="primary" className="col-md-4" onClick={() => showModal('Raffaello Cake')}>
                  Order
                </Button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 py-5 gap-4 d-flex flex-column align-items-center">
            <div className="bg-white rounded_div shadow-lg overflow-hidden flex-1 flex flex-col">
              <img
                  src="/assets/cakes/corona-cake.jpg"
                  alt=""
                  className="home__topnft_img"/>
              <div className="p-4 flex-1 flex flex-col">
                <h3 className="mb-4 text-2xl">Corona birthday/wedding cake</h3>
                <div className="mb-4 text-grey-darker text-sm flex-1">
                <p><b><i>Taste:</i></b> Tell us your preference. </p>
                  <p>&emsp;Include in the details for how many people you are going to serve. </p>
                  <p><b><i>Ingredients:</i></b> example vanilla flavor, choco flavor, tell us your preference.</p>
                </div>
                <Button type="primary" className="col-md-4" onClick={() => showModal('Birthday/Wedding Cake')}>
                  Order
                </Button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 py-5 gap-4 d-flex flex-column align-items-center">
            <div className="bg-white rounded_div shadow-lg overflow-hidden flex-1 flex flex-col">
              <img
                  src="/assets/cakes/napoleon-cake.jpg"
                  alt=""
                  className="home__topnft_img"/>
              <div className="p-4 flex-1 flex flex-col">
                <h3 className="mb-4 text-2xl">Napoleon cake</h3>
                <div className="mb-4 text-grey-darker text-sm flex-1">
                  <p><b><i>Taste:</i></b> Crunchy and sweet. </p>
                  <p>&emsp;Best to consume after keeping ~30mins in refrigerator. </p>
                  <p><b><i>Ingredients:</i></b> egg, flour, butter, condensed milk.</p>
                </div>
                <Button type="primary" className="col-md-4" onClick={() => showModal('Napoleon Cake')}>
                  Order
                </Button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 py-5 gap-4 d-flex flex-column align-items-center">
            <div className="bg-white rounded_div shadow-lg overflow-hidden flex-1 flex flex-col">
              <img
                  src="/assets/cakes/custom-cake.jpg"
                  alt=""
                  className="home__topnft_img"/>
              <div className="p-4 flex-1 flex flex-col">
                <h3 className="mb-4 text-2xl">Customized birthday/wedding cake</h3>
                <div className="mb-4 text-grey-darker text-sm flex-1">
                <p><b><i>Taste:</i></b> Tell us your preference. </p>
                  <p>&emsp;Include in the details for how many people you are going to serve. </p>
                  <p><b><i>Ingredients:</i></b> example vanilla flavor, choco flavor, tell us your preference.</p>
                </div>
                <Button type="primary" className="col-md-4" onClick={() => showModal('Birthday/Wedding Cake')}>
                  Order
                </Button>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 py-5 gap-4 d-flex flex-column align-items-center">
            <div className="bg-white rounded_div shadow-lg overflow-hidden flex-1 flex flex-col">
              <img
                  src="/assets/cakes/honey-cake.jpg"
                  alt=""
                  className="home__topnft_img"
              />
              <div className="p-4 flex-1 flex flex-col">
                <h3 className="mb-4 text-2xl">Honey cake</h3>
                <div className="mb-4 text-grey-darker text-sm">
                  <p><b><i>Taste:</i></b> soft and balanced sweet, best with coffee or tea. </p>
                  <p>&emsp;Best to consume after keeping ~30mins in refrigerator. </p>
                  <p><b><i>Ingredients:</i></b> honey, egg, milk, flour, butter, sugar, condensed milk.</p>
                </div>
                <Button type="primary" className="col-md-4" onClick={() => showModal('Honey Cake')}>
                  Order
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          styles={{
            body : { overflowY: 'auto' }
          }}
          title={orderCakeName}
          centered
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
              Order
            </Button>
          ]}>
            <Form
              labelCol={{ span: 6 }}
              layout="horizontal">
              <Form.Item label="Your name"
                rules={[{ required: true }]}>
                <Input placeholder="Full name" status={customerNameElementStatus}
                  value={customerName}
                  onChange={onCustomerNameChange}
                />
              </Form.Item>
              <Form.Item label="Requirement">
                <Input placeholder="Such as allergy restrictions"
                  value={customerRequirement}
                  onChange={onCustomerRequirementChange}
                />
              </Form.Item>
              <Form.Item label="Pick up date">
                <DatePicker
                  status={customerPreferredDateStatus}
                  value={customerPreferredDate}
                  onChange={onCustomerPreferredDate}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </Form.Item>
              <Form.Item label="Contact phone">
                <Input placeholder="example, 123-456-7890"
                  status={customerContactPhoneStatus}
                  value={customerContactPhone}
                  onChange={onCustomerContactPhoneChange}
                />
              </Form.Item>
              <Form.Item label="Quantity">
                <InputNumber defaultValue={1} min={1}
                  value={cakeOrderQuantity}
                  onChange={onCakeOrderQuantityChange}
                />
              </Form.Item>
              <Form.Item>
                <Radio.Group onChange={onChange} value={deliveryValue}>
                  <Radio value={1}>Pickup</Radio>
                  <Radio value={2}>Delivery (fee applies)</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Delivery address" style={{ display: deliveryValue !== 2 ? 'none': '' }}>
                <Input placeholder="Insert delivery address"
                  status={deliveryAddressStatus}
                  value={deliveryAddress}
                  onChange={onDeliveryAddressChange}
                />
              </Form.Item>
              <Form.Item label="Pickup address" style={{ display: deliveryValue !== 1 ? 'none': '' }}>
                <a href="https://maps.app.goo.gl/dnEcvnwva3BgcAoMA" rel="noreferrer" target="_blank">Willoughby Town Centre, Langley</a>
              </Form.Item>
            </Form>
        </Modal>
      </div>
    </>
  );
};

export default TopCakes;
